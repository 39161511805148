


























import { IAddressDataList } from "@interface/contact.interface";
import { IOption } from "@interface/common.interface";
import Vue from "vue";

export default Vue.extend({
  name: "CSelectBillingAddress",
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    propAddress: {
      type: Array as () => IAddressDataList[],
      default: []
    },
  },
  data() {
    return {
      dtOpt: [] as IOption<IAddressDataList>[],
    };
  },
  watch: {
    "propAddress.length": {
      handler: "fillOpt",
      immediate: true,
    },
  },
  methods: {
    fillOpt(): void {
      this.dtOpt = [];
      const filtered = this.propAddress.filter(x => x.billTo);
      filtered.forEach(x => {
        this.dtOpt.push({ 
          key: `${x.address}${x.cityDistrict ? ", " + x.cityDistrict : ""}${x.country ? ", " + x.country : ""}${x.postalCode ? ", " + x.postalCode : ""}`,
          value: `${x.address}${x.cityDistrict ? ", " + x.cityDistrict : ""}${x.country ? ", " + x.country : ""}${x.postalCode ? ", " + x.postalCode : ""}`,
          meta: x
        });
      });
    },
    onSelect(e: string, meta: IOption): void {
      this.$emit("on-select", {value: e, meta});
      this.$emit("input", e);
    },
    onChange(value: string): void {
      this.$emit("change", value);
      this.$emit("input", value);
    },
  },
});
